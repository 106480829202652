import React from "react";
import Seo from "../components/Layout/Seo";
import Site from "../components/Layout/Site";
import LinkagesBanner from "../components/Linkages/LinkagesBanner";
import Partners from "../components/Linkages/Partners";

const Linkages = () => {
  return (
    <Site>
      <Seo title="Linkages" />
      <LinkagesBanner />
      <Partners />
    </Site>
  );
};

export default Linkages;
