import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Banner from '../Shared/Banner'
import ScaledImage from '../UI/ScaledImage'

const LinkagesBanner = () => {
  return (
    <Banner name="Linkages" description="Forming and Maintaining<br/>Long-Term Relationships">
      <ScaledImage bg={true}>
        <StaticImage
          src="../../images/banner-linkages.jpg"
          alt="banner"
          layout="fullWidth"
          quality="100"
        />
      </ScaledImage>
    </Banner>
  )
}

export default LinkagesBanner
