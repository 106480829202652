import React from 'react'
import styled from 'styled-components'
import theme from '../../utils/theme'
import { Container, Row, Col } from '../UI/Grid'
import ScaledElement from '../UI/ScaledElement'
import { handleResponsiveSize } from '../UI/Typography'

const Banner = ({ name, description, children, col }) => {
  return (
    <ScaledElement scaleHeight={500} scaleWidth={1440} fullWidth>
      {children}
      <Container py={20} textAlign="center" color="white">
        {col ? (
          <Row justifyContent="center">
            <Col col={{ lg: col || 'auto' }}>
              <Subtitle>{name}</Subtitle>
              <Title
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              ></Title>
            </Col>
          </Row>
        ) : (
          <>
            <Subtitle>{name}</Subtitle>
            <Title
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            ></Title>
          </>
        )}
      </Container>
    </ScaledElement>
  )
}

const Subtitle = styled.div`
  text-transform: uppercase;
  margin-bottom: 4px;
  font-weight: ${theme.fontWeights.bold};
  ${handleResponsiveSize('overline')};
`

const Title = styled.div`
  font-weight: ${theme.fontWeights.bold};
  ${handleResponsiveSize('display-4')};
`

export default Banner
