import React from 'react'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import { space } from 'styled-system'
import { Title } from '../Shared/SectionHeader.styles'
import { Container, Row, Col } from '../UI/Grid'
import { Section } from '../UI/Section'
import { StaticImage } from 'gatsby-plugin-image'
import { Paragraph } from '../UI/Typography'
import Element from '../UI/Element'
import DefaultVector from '../Shared/DefaultVector'

const Partners = () => {
  return (
    <Section py={{ _: 16, lg: 0 }}>
      <DefaultVector>
        <Container>
          <PartnerWrapper mb={10}>
            <Row justifyContent="center" rowGap={5} mb={{ _: 10, lg: 16 }}>
              <Col col="12" textAlign="center">
                <StaticImage src="../../images/ideas.png" alt="ideas" height={98.31} width={217} />
              </Col>
              <Col col={{ lg: 8 }}>
                <Title textAlign="center">
                  CENTER OF INNOVATION AND DEVELOPMENT FOR ELECTRONICS AND ALLIED SERVICES (C-IDEAS)
                </Title>
              </Col>
              <Col col={{ lg: 10 }}>
                <Paragraph>
                  A collective that aims to serve as a catalyst for the continuing development of
                  the country’s electronics sector by creating a hub that will bring together the
                  different players in the electronics and allied industries. The C·IDEAS center has
                  over 12,500 square meters of space to house the diverse locator companies,
                  agencies and associations that are engaged in electronics and semiconductors. The
                  higher education institutions within the proximity of the center provide the
                  locators access to a pool of technical personnel.
                </Paragraph>
              </Col>
            </Row>

            <Row justifyContent="center" rowGap={5} mb={{ _: 6, lg: 10 }}>
              <Col col={{ lg: 8 }}>
                <Title size="h2" textAlign="center">
                  Supply Chain Locators
                </Title>
              </Col>
              <Col col={{ lg: 10 }}>
                <Paragraph>
                  Offers a wide range of electronic services such as: Calibration, Precision
                  Machining, ATE Parts & Service Support, and Adhesive Technology. Other functions
                  are also included, for example: Standards & Certification Auditors, Customer
                  Representative Offices and Representative Office of Offshore suppliers.
                </Paragraph>
              </Col>
            </Row>

            <Row
              justifyContent={{ lg: 'space-between' }}
              rowGap={{ _: 4, lg: 4 }}
              mb={{ _: 12, lg: 20 }}
              textAlign="center"
            >
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage
                  src="../../images/atmi-philippines.png"
                  alt="logo"
                  height={95}
                  width={173}
                />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage
                  src="../../images/apm-technica-ag.png"
                  alt="logo"
                  height={95}
                  width={173}
                />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage src="../../images/dx-antenna.png" alt="logo" height={95} width={173} />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage
                  src="../../images/focused-testing-inc.png"
                  alt="logo"
                  height={95}
                  width={173}
                />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage src="../../images/gth-tech.png" alt="logo" height={95} width={173} />
              </Col>
              <Element width="100%" display={{ _: 'none', lg: 'block' }} />
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage src="../../images/fsc-inc.png" alt="logo" height={95} width={173} />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage src="../../images/jademicron.png" alt="logo" height={95} width={173} />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage
                  src="../../images/mechatrp-tech.png"
                  alt="logo"
                  height={95}
                  width={173}
                />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage src="../../images/microchip.png" alt="logo" height={95} width={173} />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage src="../../images/oem-test.png" alt="logo" height={95} width={173} />
              </Col>
              <Element width="100%" display={{ _: 'none', lg: 'block' }} />
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage src="../../images/sytron.png" alt="logo" height={95} width={173} />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage
                  src="../../images/technoprobe.png"
                  alt="logo"
                  height={95}
                  width={173}
                />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage src="../../images/transphorm.png" alt="logo" height={95} width={173} />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage
                  src="../../images/vantage-resources.png"
                  alt="logo"
                  height={95}
                  width={173}
                />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <Element height={95} width={173} />
              </Col>
            </Row>

            <Row justifyContent="center" rowGap={5} mb={{ _: 6, lg: 10 }}>
              <Col col={{ lg: 8 }}>
                <Title size="h2" textAlign="center">
                  Industry, Government, and Academic Partners
                </Title>
              </Col>
              <Col col={{ lg: 10 }}>
                <Paragraph>
                  Scholarship Grants Matching and Off-Campus Post Graduate Studies are made
                  available through this alliance. These institutions also provide the C·IDEAS
                  in-house Training Facilities.
                </Paragraph>
              </Col>
            </Row>

            <Row
              textAlign="center"
              justifyContent={{ _: 'flex-start', lg: 'space-between' }}
              rowGap={{ _: 6, lg: 4 }}
            >
              <Col col={{ _: 6, xs: 4, lg: 'auto' }}>
                <StaticImage
                  src="../../images/faith-colleges.png"
                  alt="logo"
                  height={95}
                  width={173}
                />
              </Col>
              <Col col={{ _: 6, xs: 4, lg: 'auto' }}>
                <StaticImage src="../../images/mfi-logo.png" alt="logo" height={95} width={173} />
              </Col>
              <Col col={{ _: 6, xs: 4, lg: 'auto' }}>
                <StaticImage src="../../images/lpu-laguna.png" alt="logo" height={95} width={173} />
              </Col>
              <Col col={{ _: 6, xs: 4, lg: 'auto' }}>
                <StaticImage src="../../images/seipi-logo.png" alt="logo" height={95} width={173} />
              </Col>
              <Element width="100%" display={{ _: 'none', lg: 'block' }} />
              <Col col={{ _: 6, xs: 4, lg: 'auto' }}>
                <StaticImage src="../../images/bsu-logo.png" alt="logo" height={95} width={173} />
              </Col>
              <Col col={{ _: 6, xs: 4, lg: 'auto' }}>
                <StaticImage src="../../images/apc-logo.png" alt="logo" height={95} width={173} />
              </Col>
              <Col col={{ _: 6, xs: 4, lg: 'auto' }}>
                <StaticImage
                  src="../../images/malayan-colleges.png"
                  alt="logo"
                  height={95}
                  width={173}
                />
              </Col>
              <Col col={{ _: 6, xs: 4, lg: 'auto' }}>
                <StaticImage src="../../images/uplb-logo.png" alt="logo" height={95} width={173} />
              </Col>
              <Element width="100%" display={{ _: 'none', lg: 'block' }} />
              <Col col={{ _: 6, xs: 4, lg: 'auto' }}>
                <StaticImage src="../../images/uphs-logo.png" alt="logo" height={95} width={173} />
              </Col>
              <Col col={{ _: 6, xs: 4, lg: 'auto' }}>
                <StaticImage
                  src="../../images/dlsu-canlubang.png"
                  alt="logo"
                  height={95}
                  width={173}
                />
              </Col>
              <Col col={{ _: 6, xs: 4, lg: 'auto' }}>
                <StaticImage src="../../images/mirdc-logo.png" alt="logo" height={95} width={173} />
              </Col>
              <Col col={{ _: 6, xs: 4, lg: 'auto' }}>
                <StaticImage src="../../images/dost-logo.png" alt="logo" height={95} width={173} />
              </Col>
              <Element width="100%" display={{ _: 'none', lg: 'block' }} />
              <Col col={{ _: 6, xs: 4, lg: 'auto' }}>
                <StaticImage src="../../images/evap-logo.png" alt="logo" height={95} width={173} />
              </Col>
              <Col col={{ _: 6, xs: 4, lg: 'auto' }}>
                <StaticImage src="../../images/pdma.png" alt="logo" height={95} width={173} />
              </Col>
              <Col col={{ _: 6, xs: 4, lg: 'auto' }}>
                <StaticImage src="../../images/dmatel.png" alt="logo" height={95} width={173} />
              </Col>
              <Col col={{ _: 6, xs: 4, lg: 'auto' }}>
                <StaticImage src="../../images/st-logo.png" alt="logo" height={95} width={173} />
              </Col>
            </Row>
          </PartnerWrapper>

          <PartnerWrapper mb={{ lg: 20 }}>
            <Row justifyContent="center" rowGap={5} mb={{ _: 10, lg: 16 }}>
              <Col col="12" textAlign="center">
                <StaticImage
                  src="../../images/team-firstasia.png"
                  alt="team first asia"
                  height={72.72}
                  width={349.54}
                />
              </Col>
              <Col col={{ lg: 8 }}>
                <Title size="h2" textAlign="center">
                  Team First Asia Affiliates
                </Title>
              </Col>
              <Col col={{ lg: 10 }}>
                <Paragraph>
                  A Corporate Network that includes interests in property development and
                  management, freight forwarding, electronics, innovation, aviation, hotel
                  management, tourism, education, educational publishing, and social progress.
                </Paragraph>
              </Col>
            </Row>

            <Row
              justifyContent={{ lg: 'space-between' }}
              rowGap={{ _: 6, lg: 4 }}
              textAlign="center"
            >
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage
                  src="../../images/omni-aviation.png"
                  alt="logo"
                  height={95}
                  width={173}
                />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage src="../../images/bbfi.png" alt="logo" height={95} width={173} />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage
                  src="../../images/brainsparks.png"
                  alt="logo"
                  height={95}
                  width={173}
                />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage src="../../images/firstAsia.png" alt="logo" height={95} width={173} />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage
                  src="../../images/faith-colleges.png"
                  alt="logo"
                  height={95}
                  width={173}
                />
              </Col>
              <Element width="100%" display={{ _: 'none', lg: 'block' }} />
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage src="../../images/acdi-all.png" alt="logo" height={95} width={173} />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage
                  src="../../images/asset-growth-inc.png"
                  alt="logo"
                  height={95}
                  width={173}
                />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage src="../../images/afreight.png" alt="logo" height={95} width={173} />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage
                  src="../../images/asiafreight.png"
                  alt="logo"
                  height={95}
                  width={173}
                />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage
                  src="../../images/marian-orchard.png"
                  alt="logo"
                  height={95}
                  width={173}
                />
              </Col>
              <Element width="100%" display={{ _: 'none', lg: 'block' }} />

              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage
                  src="../../images/batangas-lakelands.png"
                  alt="logo"
                  height={95}
                  width={173}
                />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage
                  src="../../images/limapark-hotel.png"
                  alt="logo"
                  height={95}
                  width={173}
                />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage
                  src="../../images/suds-laundry.png"
                  alt="logo"
                  height={95}
                  width={173}
                />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage src="../../images/lima-ixl.png" alt="logo" height={95} width={173} />
              </Col>
              <Col col={{ _: 6, xs: 4, sm: 3, lg: 'auto' }}>
                <StaticImage src="../../images/diwa-innov.png" alt="logo" height={95} width={173} />
              </Col>
            </Row>
          </PartnerWrapper>
        </Container>
      </DefaultVector>
    </Section>
  )
}

const PartnerWrapper = styled.div`
  ${media.lg`
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #9E9E9E;
  `}

  ${space};
`

export default Partners
